import React, { useContext } from "react"
import styled from "styled-components"

import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { FaMobileAlt, FaMapPin, FaPlusCircle } from "react-icons/fa"
import Img from "gatsby-image"

import GlobalContext from "../../contexts/GlobalContext"

import { Section, Box, Title, Text } from "../../components/Core"
import ShapeSection from "../../sections/common/ShapeSection"
import { itemsByLang } from "../../utils/helperFn"

import bgImg from "../../assets/images/bg1.jpg"

const ContentBox = styled(Box)`
  position: relative;
  z-index: 10;
`

const Contact = () => {
  const { allPrismicContact } = useStaticQuery(graphql`
    query {
      allPrismicContact {
        nodes {
          lang
          data {
            title {
              text
            }
            intro {
              text
            }
            bg {
              url
            }
            name_placeholder {
              text
            }
            email_placeholder {
              text
            }
            phone_placeholder {
              text
            }
            company_placeholder {
              text
            }
            message_placeholder {
              text
            }
            submit_btn_text {
              text
            }

            cta_title {
              text
            }
            cta_text {
              html
            }

            location_group {
              company_name {
                text
              }
              map_url {
                url
              }
              company_logo {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              address {
                html
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicContact = itemsByLang(
    allPrismicContact.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicContact.data

  return (
    <>
      <ShapeSection bg={pData.bg ? pData.bg.url : bgImg}>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="6">
              <Title color="light" my={3}>
                {pData.title && (
                  <Title mb={3} color="light">
                    {pData.title.text}
                  </Title>
                )}
              </Title>
            </Col>
          </Row>
        </Container>
      </ShapeSection>
      <Section>
        <ContentBox>
          <Container>
            {pData.intro && (
              <Title color="dark" mb={5} className="text-center">
                {pData.intro?.text}
              </Title>
            )}

            <Row className="">
              <Col lg="8">
                <Form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  {/* You still need to add the hidden input with the form name to your JSX form */}
                  <input type="hidden" name="form-name" value="contact" />
                  <Form.Row>
                    <Form.Group as={Col} controlId="formName">
                      <Form.Control
                        type="text"
                        placeholder={pData.name_placeholder?.text || "John Doe"}
                        name="name"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Control
                        type="email"
                        placeholder={
                          pData.email_placeholder?.text || "name@company.com"
                        }
                        name="email"
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formName">
                      <Form.Control
                        type="tel"
                        placeholder={
                          pData.phone_placeholder?.text || "Your Phone"
                        }
                        name="phone"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Control
                        type="company"
                        placeholder={
                          pData.company_placeholder?.text || "Company"
                        }
                        name="company"
                        required
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridMessage">
                      <Form.Control
                        as="textarea"
                        placeholder={
                          pData.message_placeholder?.text || "Message"
                        }
                        name="message"
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="justify-content-end pt-4">
                    <Button variant="dark" type="submit" className="">
                      {pData.submit_btn_text?.text || "Submit"}
                    </Button>
                  </Form.Row>
                </Form>
              </Col>
              <Col lg="4">
                <Box bg="dark" py={4} px={4} className="text-center">
                  <Box>
                    <FaMobileAlt
                      color="#fff"
                      fontSize="40px"
                      className="mb-4"
                      css={`
                        opacity: 0.7;
                      `}
                    />
                  </Box>
                  {pData.cta_title && (
                    <Title color="light" fontSize="30px !important" mb={3}>
                      {pData.cta_title?.text}
                    </Title>
                  )}
                  {pData.cta_text && (
                    <div
                      className="text-white"
                      css={`
                        p,
                        .p {
                          font-size: 1rem !important;
                          color: white !important;
                        }
                      `}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pData.cta_text?.html,
                        }}
                      />
                    </div>
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </ContentBox>
      </Section>
      {pData.location_group.length > 0 && (
        <Section
          className="pt-0"
          css={`
            p {
              font-size: 15px !important;
            }
          `}
        >
          <ContentBox>
            <Container>
              {pData.location_group.map((location, i) => {
                return (
                  <Row
                    key={i}
                    className="bg-white rounded px-4 py-4 mb-4 shadow-sm"
                  >
                    <Col lg="5" className="mb-3 mb-lg-0 ">
                      <div>
                        {location.company_logo && (
                          <Box mb={3}>
                            <Img
                              fluid={location.company_logo.fluid}
                              alt=""
                              css={`
                                width: 200px;
                              `}
                            />
                          </Box>
                        )}
                        {location.company_name.text.length > 0 && (
                          <Title
                            variant="card"
                            fontSize="16px!important"
                            mb={3}
                          >
                            {location.company_name?.text}
                          </Title>
                        )}
                      </div>
                      {location.map_url.url.length > 0 && (
                        <div className="mb-3">
                          <a href={location.map_url.url} target="_blank">
                            <Button variant="outline-dark" size="sm">
                              <FaPlusCircle /> See Map
                            </Button>
                          </a>
                        </div>
                      )}
                    </Col>
                    <Col lg="7" className="pl-lg-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: location.address?.html,
                        }}
                        css={`
                          line-height: 1.8 !important;
                        `}
                      />
                    </Col>
                  </Row>
                )
              })}
            </Container>
          </ContentBox>
        </Section>
      )}
    </>
  )
}

export default Contact
