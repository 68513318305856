import React from "react"

import styled from "styled-components"

import { Section, Box } from "../../components/Core"

import bgSec from "../../assets/images/bg1.jpg"

const SectionStyled = styled(Section)`
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ bgImg }) => `url(${bgImg}) no-repeat center`};
    background-size: cover;
    background-attachment: fixed;
    z-index: 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    z-index: 1;
  }
`

const BoxTop = styled(Box)`
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  z-index: 2;
  top: -1px;
  svg {
    display: block;
    width: calc(124% + 1.3px);
    height: 116px;
    position: relative;
    left: 50%;

    transform: translateX(-50%);
  }
`

const BoxBottom = styled(BoxTop)`
  top: auto;
  bottom: -1px;
  transform: rotate(180deg);
  svg {
    transform: translateX(-50%) rotateY(180deg);
  }
`
const ShapeSection = ({ bg = bgSec, children, ...rest }) => {
  return (
    <>
      <SectionStyled className="position-relative" bgImg={bg} {...rest}>
        <BoxTop>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2600 131.1"
            preserveAspectRatio="none"
            className="divider-shape"
          >
            <path d="M0 0L2600 0 2600 69.1 0 0z"></path>
            <path
              css={`
                opacity: 0.5;
              `}
              d="M0 0L2600 0 2600 69.1 0 69.1z"
            ></path>
            <path
              css={`
                opacity: 0.5;
              `}
              d="M2600 0L0 0 0 130.1 2600 69.1z"
            ></path>
          </svg>
        </BoxTop>
        <Box
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          {children}
        </Box>

        <BoxBottom>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2600 131.1"
            preserveAspectRatio="none"
            className="divider-shape"
          >
            <path d="M0 0L2600 0 2600 69.1 0 0z"></path>
            <path
              css={`
                opacity: 0.5;
              `}
              d="M0 0L2600 0 2600 69.1 0 69.1z"
            ></path>
            <path
              css={`
                opacity: 0.5;
              `}
              d="M2600 0L0 0 0 130.1 2600 69.1z"
            ></path>
          </svg>
        </BoxBottom>
      </SectionStyled>
    </>
  )
}

export default ShapeSection
