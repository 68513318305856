import React, { useContext } from "react"

import { Container, Row, Col, Form } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"

import { Section, Title, Button } from "../../components/Core"
import ShapeSection from "../../sections/common/ShapeSection"
import bgImg from "../../assets/images/bg4.jpg"

const Support = () => {
  const { pathname } = useLocation()
  const siteUrl = `${`https://emeigroup.netlify.app`}${pathname}`

  const { allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicHomePage {
        nodes {
          lang
          data {
            support_title {
              text
            }
            support_bg {
              url
            }
            username_label {
              text
            }
            username_placeholder {
              text
            }
            email_label {
              text
            }
            email_placeholder {
              text
            }
            phone_label {
              text
            }
            phone_placeholder {
              text
            }
            message_label {
              text
            }
            message_placeholder {
              text
            }
            submit_btn_text {
              text
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <>
      <ShapeSection bg={pData?.support_bg ? pData?.support_bg.url : bgImg}>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="6">
              <Title color="light" my={3}>
                {pData?.support_title && (
                  <Title mb={3} color="light">
                    {pData?.support_title.text}
                  </Title>
                )}
              </Title>
            </Col>
          </Row>
        </Container>
      </ShapeSection>
      <Section>
        <Container>
          <Form
            name="support"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input type="hidden" name="form-name" value="support" />

            <Form.Row>
              <Form.Group as={Col} controlId="formName" sm={6}>
                <Form.Label>{pData?.username_label.text}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={pData?.username_placeholder.text}
                  name="username"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail" sm={6}>
                <Form.Label>{pData?.email_label.text}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={pData?.email_placeholder.text}
                  name="email"
                  required
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridPhone" sm={6}>
                <Form.Label>{pData?.phone_label.text}</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder={pData?.phone_placeholder.text}
                  name="phone"
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridFile" sm={6}>
                <Form.Label>
                  File: <small>Max file size 30M</small>
                </Form.Label>
                <Form.File
                  id="exampleFormControlFile1"
                  name="file"
                  accept="image/*, audio/*, video/*"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridURL">
                <input type="hidden" name="pageURL" value={siteUrl} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>{pData?.message_label.text}</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={pData?.message_placeholder.text}
                  name="message"
                  required
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Button type="submit">{pData?.submit_btn_text.text}</Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Container>
      </Section>
    </>
  )
}

export default Support
