import React, { useEffect, useContext } from "react"

import GlobalContext from "../../contexts/GlobalContext"

const headerConfigDefault = {
  theme: "light",
  bgClass: "",
  isFluid: false,
}

const footerConfigDefault = {
  theme: "dark",
}

const PageWrapper = ({
  children,
  headerConfig = null,
  footerConfig = null,
  className,
}) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.themeDark) {
      gContext.setHeader({
        ...headerConfigDefault,
        ...headerConfig,
        theme: "dark",
      })
      gContext.setFooter({
        ...footerConfigDefault,
        ...footerConfig,
        theme: "dark",
      })
    } else {
      gContext.setHeader({ ...headerConfigDefault, ...headerConfig })
      gContext.setFooter({ ...footerConfigDefault, ...footerConfig })
    }
  }, [gContext.themeDark, headerConfig, footerConfig])

  return <div className={className}>{children}</div>
}

export default PageWrapper
